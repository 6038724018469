import React from 'react'
import telegraphImage from 'assets/images/telegraph-logo.png'
import forbesImage from 'assets/images/forbes.png'
import condeNastLogo from 'assets/images/conde-nast-logo.png'
import robbReportLogo from 'assets/images/robb-report-logo.png'
import { Swiper, SwiperSlide } from 'swiper/react'
import { QuoteLeftIcon, QuoteRightIcon } from 'components/icons'
import Image from 'components/Image'
import Link from 'components/Link'
import { config } from 'data/config'
import { FreeMode } from 'swiper/modules'

const Testimonials = () => {
  return (
    <div className="bg-grey-300 bg-opacity-10 py-75 lg:py-[125px] text-center relative">
      <div className="relative mb-35 md:mb-[157px]">
        <div className="max-w-[1424px] w-full flex flex-col md:flex-row md:justify-center gap-10 px-20 mx-auto">

          <div className="relative sm:left-0 sm:-top-0">
            <QuoteRightIcon className="scale-[0.6] md:scale-[0.81]" />
          </div>

          <div className="max-w-[300px] mx-auto sm:max-w-[1294px] md:mt-90 w-full text-30 antialiased tracking-tight font-serif font-normal md:text-56 leading-[42px] md:leading-[75px]">
            The world’s premier luxury vacation rental company.<br className="hidden 2xl:block" /> A bespoke booking platform powered by <br className="hidden 2xl:block" /> a high-tech, high-touch approach.
          </div>

          <div className="relative self-end md:right-0 md:-bottom-20">
            <QuoteLeftIcon className="scale-[0.75] md:scale-100" />
          </div>

        </div>
      </div>

      <div className="relative items-center justify-center hidden mx-auto opacity-50 lg:flex gap-75 grayscale">
        <Link className="w-[130px] h-[60px]" href={config.telegraphLink} target="_blank">
          <Image src={telegraphImage} alt="Telegraph" layout="responsive" width={130} height={60} className="object-cover mr-15" />
        </Link>
        <Link className="w-[119px] h-[34px]" href={config.forbesLink} target="_blank">
          <Image src={forbesImage} alt="Forbes" layout="responsive" width={119} height={34} className="object-cover" />
        </Link>
        <div className="w-[110px] h-[40px]">
          <Image src={condeNastLogo} alt="Travel Conde Nast" layout="responsive" width={109} height={40} className="object-cover" />
        </div>
        <div className="w-[180px] h-[25px]">
          <Image src={robbReportLogo} alt="Robb Report" layout="responsive" width={180} height={34} className="object-cover" />
        </div>
      </div>

      <div className="lg:max-w-[685px] mx-auto lg:hidden grayscale opacity-50 justify-center">
        <Swiper
          modules={[FreeMode]}
          slidesOffsetBefore={20}
          slidesOffsetAfter={20}
          slidesPerView="auto"
          spaceBetween={50}
          freeMode={true}
        >
          <SwiperSlide className="h-[75px] flex items-center w-max">
            <Link className="w-[130px] h-[60px]" href={config.telegraphLink} target="_blank">
              <Image src={telegraphImage} alt="Telegraph" layout="responsive" width={130} height={60} className="object-cover mr-15" />
            </Link>
          </SwiperSlide>
          <SwiperSlide className="h-[75px] flex items-center w-max">
            <Link className="w-[119px] h-[34px]" href={config.forbesLink} target="_blank">
              <Image src={forbesImage} alt="Forbes" layout="responsive" width={119} height={34} className="object-cover" />
            </Link>
          </SwiperSlide>
          <SwiperSlide className="h-[75px] flex items-center w-max">
            <div className="w-[110px] h-[40px]">
              <Image src={condeNastLogo} alt="Travel Conde Nast" layout="responsive" width={109} height={40} className="object-cover"/>
            </div>
          </SwiperSlide>
          <SwiperSlide className="h-[75px] flex items-center w-max">
            <div className="w-[180px] h-[25px]">
              <Image src={robbReportLogo} alt="Robb Report" layout="responsive" width={180} height={34} className="object-cover"/>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  )
}

export default Testimonials
